/*
 * @Author: Jackie
 * @Date: 2023-07-07 19:55:06
 * @LastEditTime: 2023-07-10 15:51:25
 * @LastEditors: Jackie
 * @Description: file content
 * @FilePath: /rushduckarmy/src/router/index.js
 * @version:
 */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

/*
 * @Author: Jackie
 * @Date: 2023-07-07 20:07:39
 * @LastEditTime: 2023-07-07 20:18:10
 * @LastEditors: Jackie
 * @Description: file content
 * @FilePath: /vue3-utils-demo/src/store/modules/app.js
 * @version:
 */
const LOCAL_KEY_PREFIX = 'app_';
export default {
  namespaced: true,
  state: {
    sizeRatio: 0,
    windowWidth: document.documentElement.clientWidth,
    windowHeight: document.documentElement.clientHeight,
    loading: false,
    toastMessage: ''
  },
  getters: {},
  mutations: {
    setSizeRatio(state, payload) {
      state.sizeRatio = payload;
    },
    setWindowWidth(state, payload) {
      state.windowWidth = payload;
    },
    setWindowHeight(state, payload) {
      state.windowHeight = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    showLoading({ commit }) {
      commit('setLoading', true);
    },
    hideLoading({ commit }) {
      commit('setLoading', false);
    },
    showToast({ commit }, content) {
      console.log(content);
      //   ElMessage({
      //     message: content.msg,
      //     duration: content.duration || 3000,
      //     type: content.type || 'success'
      //   })
    }
  }
};

/*
 * @Author: Jackie
 * @Date: 2023-07-07 19:55:06
 * @LastEditTime: 2023-07-07 20:29:12
 * @LastEditors: Jackie
 * @Description: file content
 * @FilePath: /vue3-utils-demo/src/main.js
 * @version:
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/utils/autoResize';

createApp(App).use(store).use(router).mount('#app');

/*
 * @Author: Jackie
 * @Date: 2023-07-07 19:55:06
 * @LastEditTime: 2023-07-07 20:09:31
 * @LastEditors: Jackie
 * @Description: file content
 * @FilePath: /vue3-utils-demo/src/store/index.js
 * @version:
 */
import { createStore } from 'vuex';
import app from './modules/app.js';
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    app
  }
});
